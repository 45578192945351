import React from "react";
import Meta from "./../components/Meta";
import HeroSection from "./../components/HeroSection";
import FeaturesSection from "./../components/FeaturesSection";
import TestimonialsSection from "./../components/TestimonialsSection";
import CtaSection from "./../components/CtaSection";
import CtaPopup from "../components/CtaPopup";
import classroom from "../assets/classroom.png";

function IndexPage(props) {
  return (
    <>
      <Meta />
      <HeroSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Experience the Beast Mode Advantage"
        subtitle="Our unique platform makes it easy for your student - with our expert tutors that have gone through your student's struggles, tutors can relate better and develop methods to help your students in a more applicable way."
        image={classroom}
        buttonText="Grab your free session NOW!"
        buttonColor="primary"
        buttonPath="/contact"
      />
      <TestimonialsSection
        bgColor="light"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Here's what people are saying"
        subtitle=""
      />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Why Choose Us?"
        subtitle=""
      />
      {/*
      <ClientsSection
        bgColor="light"
        size="normal"
        bgImage=""
        bgImageOpacity={1}
        title=""
        subtitle=""
      />
      */}
      <CtaSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Ready to get started?"
        subtitle=""
        buttonText="Grab your free session NOW!"
        buttonColor="primary"
        buttonPath="/contact"
      />
      <CtaPopup />
    </>
  );
}

export default IndexPage;
