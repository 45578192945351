import React from "react";
import Meta from "./../components/Meta";
import HeroSection2 from "./../components/HeroSection2";

function AboutPage(props) {
  return (
    <>
      <Meta title="About" description="Learn about our company and team" />
      <HeroSection2
        bgColor="primary"
        size="large"
        bgImage="https://images.unsplash.com/photo-1462206092226-f46025ffe607?auto=format&fit=crop&w=1600&h=800&q=80"
        bgImageOpacity={0.2}
        title="Mission statement"
        subtitle="At Beast Mode Test Prep, we are dedicated to unlocking the full academic potential of every student. We specialize in comprehensive test preparation, providing tailored tutoring that adapts to each student's unique learning style. Our mission is to not only prepare students for their SATs, ACTs, and other standardized tests but also to instill a sense of academic confidence and mastery of test-taking strategies. We are committed to fostering an environment where learning is engaging and students are equipped with the critical skills necessary to excel. Through our personalized approach and expert guidance, Beast Mode Test Prep empowers students to achieve their highest academic aspirations and conquer their educational challenges."
      />
      {/*
      <TeamBiosSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Meet the Team"
        subtitle=""
      />
      */}
    </>
  );
}

export default AboutPage;
