import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import teamwork from "../assets/teamwork.svg";
import skill from "../assets/skill.svg";
import groupTeam from "../assets/group-team.svg";
// https://iconscout.com/illustrations/teamwork
const useStyles = makeStyles((theme) => ({
  itemsContainer: {
    marginTop: 60,
  },
  row: {
    // Reverse every other row
    "&:nth-of-type(even)": {
      flexDirection: "row-reverse",
    },

    // Spacing between rows
    "&:not(:last-child)": {
      marginBottom: `${theme.spacing(3)}px`,
    },
  },
  figure: {
    maxWidth: 300,
    margin: "30px auto",
  },
  image: {
    height: "auto",
    maxWidth: "100%",
  },
}));

function FeaturesSection(props) {
  const classes = useStyles();

  const items = [
    {
      title: "Support",
      description:
        "We understand that every student learns differently, which is why our approach is tailored to accommodate visual, auditory, and kinesthetic learners, ensuring optimal comprehension and retention. Our educational support is grounded in the recognition of these distinct learning styles, providing personalized strategies to empower each student. Through this tailored support, we aim to boost academic performance and build confidence in learners of all types.",
      image: groupTeam,
    },
    {
      title: "Engage",
      description:
        "Our group tutoring sessions are designed to foster a collaborative learning environment where students can engage with peers while focusing on their individual learning objectives. Flexibility is key in our program, allowing students to determine the frequency and duration of sessions that best align with their aspirations and academic targets. This structure encourages active participation, ensuring that every session moves them closer to achieving their educational dreams.",
      image: teamwork,
    },
    {
      title: "Skill",
      description:
        "Our holistic approach to tutoring goes beyond traditional test preparation; we equip students with valuable life skills, such as critical thinking, time management, and effective communication. These skills are integrated into our curriculum, preparing students for success in various settings, from the classroom to real-world challenges. We are committed to shaping well-rounded individuals who are ready to excel academically and thrive in their personal growth and future endeavors.",
      image: skill,
    },
    // {
    //   title: "Explore",
    //   description:
    //     "Integer ornare neque mauris, ac vulputate lacus venenatis et. Pellentesque ut ultrices purus.",
    // },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Container
          maxWidth="md"
          disableGutters={true}
          className={classes.itemsContainer}
        >
          {items.map((item, index) => (
            <Grid
              className={classes.row}
              container={true}
              item={true}
              alignItems="center"
              spacing={4}
              key={index}
            >
              <Grid item={true} xs={12} md={6}>
                <Box
                  textAlign={{
                    xs: "center",
                    md: "left",
                  }}
                >
                  <Typography variant="h5" gutterBottom={true}>
                    {item.title}
                  </Typography>
                  <Typography variant="subtitle1">
                    {item.description}
                  </Typography>
                </Box>
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <figure className={classes.figure}>
                  <img
                    src={item.image}
                    alt={item.title}
                    className={classes.image}
                  />
                </figure>
              </Grid>
            </Grid>
          ))}
        </Container>
      </Container>
    </Section>
  );
}

export default FeaturesSection;
