import React, { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import logo from "../assets/logo.svg";
import pawlogo from "../assets/paw-logo-black.svg";
import emailjs from 'emailjs-com';

const PROMPT_TEXT = "Sign up for a free session today!";
const THANK_YOU_TEXT = "Thank you for signing up!";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  width: { xs: "90%", sm: "80%", md: 700 },
  maxHeight: "calc(100% - 96px)",
  overflowY: "auto",
  "&:focus": {
    outline: "none",
  },
};

function CtaPopup() {
  const [open, setOpen] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpen(true);
    }, 3000); // Delay the popup for 10000 milliseconds (10 seconds)

    emailjs.init('bmtestprep@gmail.com'); // TODO add to env
    
    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Get the email value from the form
    const email = event.target.elements.email.value;

    // Prepare the email data
    const templateParams = {
      to_name: 'bmtestprep@gmail.com',
      from_name: email,
      message: `${email} is interseted in free session.`,
      reply_to: email,
    };

     // Send the email TODO add to env
    emailjs.send('service_xs5pecp', 'template_b8ob8fs', templateParams, 'yTksOCuKSC0vvX4zJ')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        setIsSubmitted(true);
      }, (error) => {
        console.log('FAILED to send email...', error);
      });
  };

  const formContent = (
    <>
      <Typography
        id="signup-modal-description"
        sx={{
          fontSize: "1.125rem", // Increased font size from 1rem to 1.125rem
          fontWeight: "bold", // Changed font weight from 'medium' to 'bold' for more emphasis
          mb: 2,
          textAlign: "center"
        }}
      >
        {PROMPT_TEXT}
      </Typography>
      <Box component="form" onSubmit={handleSubmit} sx={{ mt: 2 }}>
        <TextField
          required
          label="Email Address"
          type="email"
          fullWidth
          variant="outlined"
          margin="normal"
          name="email"
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{
            mt: 2,
            bgcolor: "black", // Set the button background to black
            color: "white", // Set the text color to white (optional, for better contrast)
            fontWeight: "bold", // Make the text bold
            "&:hover": {
              bgcolor: "black", // Keep the button black on hover
            },
          }}
        >
          I'M INTERESTED
        </Button>
      </Box>
    </>
  );

  const thankYouContent = (
    <Typography
      sx={{
        fontSize: "1.25rem",
        fontWeight: "medium",
        mb: 2,
        textAlign: "center",
      }}
    >
      {THANK_YOU_TEXT}
    </Typography>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropProps={{
        timeout: 500,
      }}
      aria-labelledby="signup-modal-title"
      aria-describedby="signup-modal-description"
    >
      <Fade in={open} timeout={500}>
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2} alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={6}>
              <Box
                component="img"
                src={logo}
                alt="Beast Mode Test Prep Logo"
                sx={{
                  width: "100%",
                  maxWidth: "200px",
                  height: "auto",
                  mb: 1,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              />
              {isSubmitted ? thankYouContent : formContent}
              {!isSubmitted && (
                <Typography
                  variant="body2"
                  sx={{
                    color: "gray",
                    cursor: "pointer",
                    mt: 2,
                    textAlign: "center",
                  }}
                  onClick={handleClose}
                >
                  Not interested. Close Window.
                </Typography>
              )}
            </Grid>
            <Grid
              item
              xs={0}
              sm={6}
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                component="img"
                src={pawlogo}
                alt="Paw Logo"
                sx={{ 
                  width: "100%", 
                  maxWidth: "250px", 
                  height: "auto", 
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </Fade>
    </Modal>
  );
}

export default CtaPopup;
