import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import LinkMui from "@material-ui/core/Link";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import { Link } from "./../util/router";
import facebook from "../assets/icon-facebook.svg";
import instagram from "../assets/icon-instagram.svg";
import twitter from "../assets/icon-twitter.svg";
import PlaceIcon from '@mui/icons-material/Place';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles((theme) => ({
  sticky: {
    marginTop: "auto",
  },
  brand: {
    display: "block",
    height: 48,
  },
  listItem: {
    paddingTop: 2,
    paddingBottom: 2,
    paddingLeft: 12,
    paddingRight: 12,
  },
  listItemTextHeader: {
    fontWeight: "bold",
  },
  socialIcon: {
    minWidth: 30,
  },
  legal: {
    marginTop: theme.spacing(3),
    fontSize: "0.875rem",
    opacity: 0.6,
    "& a": {
      color: "inherit",
      marginLeft: "0.8rem",
    },
  },
  noPadding: {
    minWidth: 'auto', // Remove the minWidth to allow natural sizing
    marginRight: theme.spacing(1), // Adjust right margin as needed
    marginLeft: theme.spacing(-2),
    paddingTop: 8,
    paddingLeft: 0, // Remove padding
    paddingRight: 0,
  },
  grayLink: {
    color: "#000000", // Or any gray color you want, like '#808080'
    '&:hover': {
      color: "#000000", // Or the same gray color for hover state
    },
    fontSize: "0.875rem",
  },
  blueLink: {
    fontSize: "0.875rem",
  },
}));

function Footer(props) {
  const classes = useStyles();

  // Use inverted logo if specified
  // and we are in dark mode
  const logo = props.logo;

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
      className={props.sticky && classes.sticky}
    >
      <Container>
        <Grid container={true} justifyContent="space-between" spacing={4}>
          <Grid item={true} xs={12} md={4}>
            <Link to="/">
              <img src={logo} alt="Logo" className={classes.brand} />
            </Link>

            {props.description && (
              <Box mt={3}>
                <Typography
                  variant="overline"
                  className={classes.listItemTextHeader}
                >
                  Contact Us
                </Typography>

                <List disablePadding={true}>
                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.noPadding}>
                      <PlaceIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <LinkMui
                        component="a"
                        href="https://maps.app.goo.gl/GQQUWNwM1YLFR96Y7"
                        target="_blank"
                        rel="noopener noreferrer"
                        className={classes.grayLink} // Apply the class here
                      >
                        175 Forest St, Waltham, MA 02452
                      </LinkMui>
                    </ListItemText>
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.noPadding}>
                      <PhoneIphoneIcon />
                    </ListItemIcon>
                    <LinkMui
                      component="a"
                      href="tel:+14138008200"
                      className={classes.blueLink}
                    >
                      +1 (413) 800-8200
                    </LinkMui>
                  </ListItem>

                  <ListItem className={classes.listItem}>
                    <ListItemIcon className={classes.noPadding}>
                      <EmailIcon />
                    </ListItemIcon>
                    <LinkMui
                      component="a"
                      href="mailto:bmtestprep@gmail.com" // Corrected href with 'mailto:'
                      className={classes.grayLink}
                    >
                      bmtestprep@gmail.com
                    </LinkMui>
                  </ListItem>
                </List>
              </Box>
            )}

            <div className={classes.legal}>
              {props.copyright}
              <LinkMui component={Link} to="/legal/terms-of-service">
                Terms
              </LinkMui>
              <LinkMui component={Link} to="/legal/privacy-policy">
                Privacy
              </LinkMui>
            </div>
          </Grid>
          <Grid item={true} xs={12} md={6}>
            <Grid container={true} justifyContent="flex-end" spacing={4}>
              
              <Grid item={true} xs={12} md={4}>
                <List disablePadding={true}>
                  <ListItem className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      About Us
                    </Typography>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/about"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText>About</ListItemText>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/contact"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText>Contact</ListItemText>
                  </ListItem>
                  <ListItem
                    button={true}
                    component="a"
                    href="https://medium.com"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemText>Blog</ListItemText>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/pricing"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText>Pricing</ListItemText>
                  </ListItem>
                  <ListItem
                    component={Link}
                    to="/faq"
                    button={true}
                    className={classes.listItem}
                  >
                    <ListItemText>FAQ</ListItemText>
                  </ListItem>
                </List>
              </Grid>
              <Grid item={true} xs={12} md={4}>
                <List disablePadding={true}>
                  <ListItem className={classes.listItem}>
                    <Typography
                      variant="overline"
                      className={classes.listItemTextHeader}
                    >
                      Social
                    </Typography>
                  </ListItem>
                  <ListItem
                    button={true}
                    component="a"
                    href="https://twitter.com/taylorswift13"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img src={twitter} alt="Twitter" />
                    </ListItemIcon>
                    <ListItemText>Twitter</ListItemText>
                  </ListItem>
                  <ListItem
                    button={true}
                    component="a"
                    href="https://facebook.com/"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img src={facebook} alt="Facebook" />
                    </ListItemIcon>
                    <ListItemText>Facebook</ListItemText>
                  </ListItem>
                  <ListItem
                    button={true}
                    component="a"
                    href="https://instagram.com"
                    target="_blank"
                    rel="noreferrer"
                    className={classes.listItem}
                  >
                    <ListItemIcon className={classes.socialIcon}>
                      <img src={instagram} alt="Instagram" />
                    </ListItemIcon>
                    <ListItemText>Instagram</ListItemText>
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default Footer;
