import React from "react";
import Navbar from "./../components/Navbar";
import IndexPage from "./index";
import AboutPage from "./about";
import FaqPage from "./faq";
import ContactPage from "./contact";
import LegalPage from "./legal";
import ProgramOverviewPage from "./program-overview";
import { Switch, Route, Router } from "./../util/router";
import PurchasePage from "./purchase";
import NotFoundPage from "./404";
import Footer from "./../components/Footer";
import "./../util/analytics";
// import Chat from "./../components/Chat";
import { ThemeProvider } from "./../util/theme";

import logo from "../assets/logo.svg";

function App(props) {
  return (
    <ThemeProvider>
      <Router>
        <>
          <Navbar
            color="default"
            logo={logo}
            logoInverted={logo} // TODO create inverted logo
          />

          <Switch>
            <Route exact path="/" component={IndexPage} />

            <Route exact path="/about" component={AboutPage} />

            <Route exact path="/faq" component={FaqPage} />

            <Route exact path="/contact" component={ContactPage} />

            <Route exact path="/legal/:section" component={LegalPage} />

            <Route
              exact
              path="/program-overview"
              component={ProgramOverviewPage}
            />

            <Route exact path="/purchase/:plan" component={PurchasePage} />

            <Route component={NotFoundPage} />
          </Switch>

          <Footer
            bgColor="default"
            size="medium"
            bgImage=""
            bgImageOpacity={1}
            description="A short description of what you do here"
            copyright={`© ${new Date().getFullYear()} Beast Mode Test Prep`}
            logo={logo}
            logoInverted={logo} // TODO create inverted logo
            sticky={true}
          />
        </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
