import React from "react";
import Meta from "./../components/Meta";
import ContactSection from "./../components/ContactSection";

function ContactPage(props) {
  return (
    <>
      <Meta title="Contact" />
      <ContactSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Contact Us"
        subtitle="At Beast Mode Test Prep, our passion is your satisfaction. If you have questions, need support, or want to share feedback, we're eager to assist. Reach out today and let our dedicated team provide the personalized support you deserve!"
        buttonText="Send message"
        buttonColor="primary"
        showNameField={true}
        embedSrc="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2946.98189966725!2d-71.22436522327693!3d42.38553207119051!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e39d36c9ba1a47%3A0x861ff9c80e2690aa!2sBentley%20University!5e0!3m2!1sen!2sus!4v1699312137189!5m2!1sen!2sus"
      />
    </>
  );
}

export default ContactPage;
