import React from 'react';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import * as colors from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';

const themeConfig = {
  // Only the light theme configuration remains here, can configure dark mode
  light: {
    palette: {
      type: 'light',
      primary: {
        main: colors.indigo['500'],
      },
      secondary: {
        main: colors.pink['500'],
      },
      background: {
        default: '#fff',
        paper: '#fff',
      },
    },
    typography: {
      fontSize: 14,
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1200,
        xl: 1920,
      },
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '#root': {
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
            '& > *': {
              flexShrink: 0,
            },
          },
        },
      },
    },
  }
};

function getTheme() {
  // Always returns the light theme
  return createTheme({
    ...themeConfig.light,
    overrides: {
      ...themeConfig.light.overrides,
    },
  });
}

export const ThemeProvider = (props) => {
  // Always use the light theme
  const theme = getTheme();

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  );
};
