import React from "react";
import Meta from "./../components/Meta";
import FeaturesSection from "./../components/FeaturesSection";

function ProgramOverviewPage(props) {
  return (
    <>
      <Meta title="Program Overview" />
      <FeaturesSection
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
        title="Program Overview"
        subtitle="All the features you need to accelerate your education"
      />
      {/*
      <FeaturesSection2
        bgColor="default"
        size="medium"
        bgImage=""
        bgImageOpacity={1}
      />
      */}
    </>
  );
}

export default ProgramOverviewPage;
