import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import Box from "@material-ui/core/Box";
import Avatar from "@material-ui/core/Avatar";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";

const useStyles = makeStyles((theme) => ({
  card: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  nameBox: {
    textAlign: "center",
    paddingTop: theme.spacing(1), // Default padding for small devices
    [theme.breakpoints.up('sm')]: {
      paddingTop: theme.spacing(2), // Increased padding for larger devices
    },
  },
}));

function TestimonialsSection(props) {
  const classes = useStyles();

  const items = [
    {
      avatar: "Hannah",
      name: "Hannah",
      testimonial:
        "Crystal always displayed kindness and patience when working with our youngest participants, often volunteering to work one on one with a student who required extra assistance. I have thoroughly enjoyed working with Crystal and watching her shine in her role as a Junior Mentor. I know she will do amazing things in the future.",
      company: "Science Club for Girls",
    },
    {
      avatar: "Laura",
      name: "Laura",
      testimonial:
        "This role is multifaceted blending both scientific and leadership skills. In addition to having the ability to convey the scientific concepts to the girls, Crystal is responsible, thoughtful, engaging, and relatable. This was evident in the great relationships she built with the students. She is a valuable role model and mentor and I'd welcome the chance to work with her again!",
      company: "Science Club for Girls",
    },
    {
      avatar: "Christine",
      name: "Christine",
      testimonial:
        "Crystal brought her passion for science, positive energy and infectious joy to every club session. Crystal easily connected with our participants and helped create a truly supportive and empowering experience for girls and young women in STEM.",
      company: "Science Club for Girls",
    },
  ];

  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <SectionHeader
          title={props.title}
          subtitle={props.subtitle}
          size={4}
          textAlign="center"
        />
        <Grid container={true} justifyContent="center" spacing={4}>
          {items.map((item, index) => (
            <Grid item={true} xs={12} sm={4} md={4} key={index}>
              <Card className={classes.card}>
                <Box display="flex" justifyContent="center" pt={3}>
                  <Avatar
                    src={item.avatar}
                    alt={item.name}
                    className={classes.avatar}
                  />
                </Box>
                <CardContent>
                  <Box className={classes.nameBox}>
                    <Typography variant="body2" component="p">
                      {item.name}
                    </Typography>
                    <Box mt={2}>
                      <Typography variant="body1" component="p">
                        "{item.testimonial}"
                      </Typography>
                      {/*
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                      >
                        {item.company}
                      </Typography>
                                          
                    */}
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Section>
  );
}

export default TestimonialsSection;
